<template>
  <div>
    <div class="row mx-2 my-0" v-if="isLoading">
      <div
        class="
          col-12 col-md-10 col-lg-8
          offset-md-1 offset-lg-2
          card
          light-shadow
          border-0
          p-0
        "
      >
        <alert class="m-0" />
      </div>
    </div>

    <div v-else class="row mx-2 my-0">
      <div
        class="
          col-12 col-md-10 col-lg-8
          offset-md-1 offset-lg-2
          card
          theme-card
          border-lg-0
          py-5
          px-4
        "
      >
        <h1 class="mb-5">Edit Class</h1>
        <form @submit.prevent="saveClassification" validate>
          <input-control
            type="horizontal"
            v-model="classification.subject"
            labelClass="col-12 col-md-3 col-lg-3"
            required
            >Class Subject</input-control
          >

          <select-control
            type="horizontal"
            v-model="classification.role_id"
            :options="userTypes"
            required
            labelClass="col-12 col-md-3 col-lg-3"
            >User Type</select-control
          >

          <save classes="mt-3 save-button btn btn-theme" :saving="saving"
            >Save</save
          >
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  name: "CreateUser",
  data() {
    return {
      isLoading: true,
      saving: false,
      classification: {
        subject: "",
        role_Id: "",
      },
      userTypes: [
        //number based on the role_id used in the System
        { value: 3, label: "Client" },
        { value: 2, label: "Provider" },
      ],
    };
  },
  mounted() {
    let vm = this;
    this.getClassification(this.$route.params.id).then(function (resonse) {
      vm.classification = resonse;
      vm.isLoading = false;
    });
  },

  methods: {
    ...mapActions({
      getClassification: "classifications/get",
      updateClassification: "classifications/update",
    }),
    saveClassification: function () {
      this.saving = true;
      this.$forceUpdate();
      this.updateClassification(this.classification).then(() => {
        this.saving = false;
        this.$forceUpdate();
      });
    },
  },
};
</script>
